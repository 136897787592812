<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <totales-parking
        ref="totalesP"
        :total-dis="totalDis"
        :total-ocupados="totalOcupados"
      ></totales-parking>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <lugares
        ref="lugaresP"
        :lugares="lugares"
      ></lugares>
    </v-col>
  </v-row>
</template>

<script>
import TotalesParking from './TotalesParking'
import Lugares from './Lugares'
import parking from '@/services/parking'

export default {
  name: 'Dashboard',
  components: {
    TotalesParking,
    Lugares,
  },
  data: () => ({
    lugares: [],
    totalOcupados: 0,
    totalDis: 0,
    loading: true,
    error: false,
    errMessage: '',
    fechaActual: '',
  }),
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      parking
        .getDataParking()
        .then(resp => {
          if (resp.data.error) {
            this.$refs.totalesP.setError(resp.data.error)
            this.$refs.lugaresP.setError(resp.data.error)
            this.loading = false
          } else {
            this.lugares = resp.data
            resp.data.forEach(element => {
              if (element.disponibilidad === 'Libre') {
                this.totalDis += 1
              } else {
                this.totalOcupados += 1
              }
            })
            this.loading = false
          }
        })
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() { },
}
</script>
