<template>
  <v-card>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-sheet>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </div>
      <v-simple-table>
        <template
          v-if="!loading && !error"
          v-slot:default
        >
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Disponibilidad</th>
              <th>Calle</th>
              <th>Tiempo Ocupado/Libre</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="lugar in lugares"
              :key="lugar.nombre"
            >
              <td>{{ lugar.nombre }}</td>
              <td>
                <v-avatar
                  size="30"
                  :color="lugar.disponibilidad ==='Libre'? 'success': 'error'"
                  rounded
                  class="elevation-1 mr-2"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiCar }}
                  </v-icon>
                </v-avatar> {{ lugar.disponibilidad }}
              </td>
              <td>{{ lugar.calle }}</td>
              <td>{{ lugar.hrs }} hrs {{ lugar.minutos }} min</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCar } from '@mdi/js'

export default {
  props: {
    lugares: {
      type: Array,
      default() {
        return {}
      },
    },
  },
  data: () => ({
    loading: true,
    error: false,
    errMessage: '',
    fechaActual: '',
  }),
  mounted() {
    this.loading = false
  },
  methods: {
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  setup() {
    return {
      // icons
      icons: {
        mdiCar,
      },
    }
  },
}
</script>
