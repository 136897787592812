import axios from 'axios'
import environment from '@/environments/environment'

const path = environment.apiUrl

export default {
  getDataParking() {
    return axios.get(`${path}/parking`, {})
  },
}
