<template>
  <v-card>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </div>
      <v-container
        v-if="!loading && !error"
      >
        <v-row>
          <v-col
            cols="12"
            md="5"
            offset="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCar }}
              </v-icon>
            </v-avatar>
            <div class="ms-2">
              <p class="text-xs mb-0 font-weight-bold">
                Número Total de <br />Lugares Disponibles
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ totalDis }}
              </h3>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCar }}
              </v-icon>
            </v-avatar>
            <div class="ms-2">
              <p class="text-xs mb-0 font-weight-bold">
                Número Total de <br>Lugares Ocupados
              </p>
              <h3 class="text-xl font-weight-bold">
                {{ totalOcupados }}
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCar } from '@mdi/js'

export default {
  props: {
    totalDis: {
      type: Number,
      default() {
        return {}
      },
    },
    totalOcupados: {
      type: Number,
      default() {
        return {}
      },
    },
  },
  data: () => ({
    loading: true,
    error: false,
    errMessage: '',
  }),
  mounted() {
    this.loading = false
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
  setup() {
    return {
      // icons
      icons: {
        mdiCar,
      },
    }
  },
}
</script>
